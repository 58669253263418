import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from '@mui/material';
import { FormControl,MenuItem } from '@mui/material';
import { InputLabel } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { ReservationContext } from './ReservationContext';
import { StepperContext } from './StepperContext';

import Button from '@mui/material/Button';  
function SelectDate() {

  const navigate = useNavigate();
    const stepperContext = useContext(StepperContext);  

const { handleNext } = stepperContext; 
  const { reservation, setReservation } = useContext(ReservationContext);
  const [blockedDates, setBlockedDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);

  const fetchBlockedDates = (month, year) => {
    axios.get(`https://happyland-reservas.it-unit.cl/administrador/ordenes/bloqueosmes/1/${month}/${year}`)
      .then(response => {
        const blockedDays = response.data.map(date => dayjs(date));
        setBlockedDates(blockedDays);
      })
      .catch(error => {
        console.error('Error fetching blocked dates:', error);
      });
  };

  const fetchAvailableTimes = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    axios.get(`https://happyland-reservas.it-unit.cl/administrador/ordenes/traerhorariolocal/1/?d=${formattedDate}`)
      .then(response => {
        const times = response.data.map(time => ({
          id: time.id_hr,
          hora: time.hora,
          cupos_disponibles: time.cupos_disponibles,
        }));
        setAvailableTimes(times);
      
      })
      .catch(error => {
        console.error('Error fetching available times:', error);
      });
  };

  useEffect(() => {
    setReservation((reservation) => ({ ...reservation,  id_hr: Array.isArray(reservation.id_hr) ? reservation.id_hr : [], }));
    handleNext(0);
    document.body.style.backgroundColor = '#f9006f';
 
   
    const today = dayjs();
  
    fetchBlockedDates(today.month() + 1, today.year()); // Cargar fechas bloqueadas del mes actual
      while (isDateBlocked(today)){
        today.add(1,'day');
      }
      handleDateChange(today);  
  
    return () => {
      document.body.style.backgroundColor = '';  
    };
  }, []);

  if (!stepperContext) {
    console.error('StepperContext is undefined');
    return null;
  }
  
  const handleDateChange = (newDate) => {
    setReservation({ ...reservation, fecha: newDate, time:'',id_hr: [],cant:1,lanes:1 });
    fetchAvailableTimes(newDate); // Buscar horarios disponibles para la nueva fecha
  };

  const handleMonthChange = (newDate) => {
    fetchBlockedDates(newDate.month() + 1, newDate.year());
  };

 
  const handleTimeChange = (event) => {
    const selectedValues = event.target.value;
    if (selectedValues.length <= 2) {
    // Obtener las horas seleccionadas en orden ascendente
    const selectedTimes = selectedValues
      .map((id) => availableTimes.find((time) => time.id === id))
      .filter((time) => time) // Filtrar valores nulos
      .map((time) => time.hora)
      .sort(); // Asegurarte de que están ordenadas
  
    // Validar si las horas son contiguas
    const isContiguous = selectedTimes.every((time, index, array) => {
      if (index === 0) return true; // Ignorar el primer elemento
      const prevTime = dayjs(array[index - 1], "HH:mm"); // Convertir a objeto Day.js
      const currTime = dayjs(time, "HH:mm");
      return currTime.diff(prevTime, "minute") === 60; // Diferencia de exactamente 1 hora
    });
  
    if (!isContiguous) {
      alert("Por favor selecciona horarios contiguos.");
      return; // Cancelar la actualización del estado
    }
  
    setReservation({
      ...reservation,
      id_hr: selectedValues,
      time: selectedValues.map((id) => {
        const selectedTime = availableTimes.find((time) => time.id === id);
        return selectedTime ? selectedTime.hora : null;
      })  .filter((hora) => hora !== null) // Filtrar valores nulos
      .join(', '), // Unir las horas seleccionadas por comas
    });
  }
  };
  const handleLanesChange = (event) => {
    const inputValue = parseInt(event.target.value, 10) || 1; // Asegurar que sea un número válido
    console.log(inputValue);
  
    let maxCupos = 1; // Valor por defecto si no hay horarios seleccionados
  
    if (reservation.id_hr && reservation.id_hr.length > 0) {
      maxCupos = Math.min(...reservation.id_hr.map((id) => {
        const selectedTime = availableTimes.find((time) => time.id === id);
        return selectedTime ? selectedTime.cupos_disponibles*4 : 1;
      }));
    }
  
    // Asegurarse de que el valor no supere el máximo permitido
    const cant = Math.min(inputValue, maxCupos); 
    setReservation({ ...reservation, cant });
  };

  const isDateBlocked = (date) => {
    return blockedDates.some(blockedDate => blockedDate.isSame(date, 'day'));
  };

  return (
    <div  >
      
      <form onSubmit={() => navigate('/enter-details')}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DateCalendar
            value={reservation.fecha}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange} // Actualiza las fechas bloqueadas cuando cambia el mes
            shouldDisableDate={isDateBlocked} // Deshabilitar fechas bloqueadas
          />
        </LocalizationProvider>
        <FormControl fullWidth>
  <InputLabel htmlFor="time">Bloque Horario</InputLabel>
  <Select
    id="time"
    name="time"
      value={reservation.id_hr || []}  
    onChange={handleTimeChange}
    multiple
    required
  >
    {availableTimes.map((time) => (
      <MenuItem key={time.id} value={time.id}>
        {time.hora}  
      </MenuItem>
    ))}
  </Select>
  <small className='leftp' > Máximo 2 Horas.</small>
</FormControl>
       
        <label className='leftp' htmlFor="lanes">Cantidad de Personas</label>
        <input
  type="number"
  id="lanes"
  name="lanes"
  min="1"
  max={
    reservation.id_hr && reservation.id_hr.length > 0
      ? Math.min(...reservation.id_hr.map((id) => {
          const selectedTime = availableTimes.find((time) => time.id === id);
          const cm=selectedTime.cupos_disponibles>2?2:1;
          return selectedTime ? cm*4 : 1;
        }))
      : 1
  }
  value={reservation.cant}
  onChange={handleLanesChange}
  required
  disabled={!reservation.id_hr || reservation.id_hr.length === 0}
/>
<small className="leftpb2">
                {reservation.cant!=0 && reservation.id_hr && reservation.id_hr.length > 0 && Math.ceil(reservation.cant / 4)==1 ?   Math.ceil(reservation.cant / 4)+' Pista':   ''}
                {reservation.cant!=0 && reservation.id_hr && reservation.id_hr.length > 0 && Math.ceil(reservation.cant / 4)>1 ?   Math.ceil(reservation.cant / 4)+' Pistas':   ''}
            </small>
               <small className="leftp">

    {reservation.id_hr && reservation.id_hr.length > 0
    ? Math.min(...reservation.id_hr.map((id) => {
        const selectedTime = availableTimes.find((time) => time.id === id);
        return selectedTime ? selectedTime.cupos_disponibles : 0;
      }))+' Pistas Disponibles'
    : 'Seleccione Horario Para continuar'}  
</small>
        <small className='leftp' > Máximo 4 Personas por pista. No aseguramos que las pistas reservadas sean continuas (queden juntas).</small>
 
        <p></p>
        <Button type="submit" color="success" variant="contained">Siguiente</Button>
        
      </form>
    </div>
  );
}

export default SelectDate;